import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import tooth from '../../images/tooth-icon.svg';
import { SecondaryBtn } from '../Buttons/Secondary';
import { h1 } from './../Headings';

const Section = styled.section`
    background: ${({ theme }) => theme.white};
    padding: 0 calc((100vw - 1732px) / 2);

    @media (min-width: 1280px) {
        display: flex;
        align-items: flex-start;
    }
`;

const ImgContainer = styled.div`
    max-width: 550px;

    @media (min-width: 1600px) {
        max-width: 700px;
    }

    @media (min-width: 1800px) {
        max-width: 822px;
    }

    @media (min-width: 1900px) {
        max-width: 944px;
    }
`;

const Box = styled.div`
    margin: 16px;
    max-width: 830px;

    > h2 {
        margin: 0;
        ${h1};
    }

    > a {
        margin-top: 16px;
    }

    @media (min-width: 1024px) {
        margin: 32px;
    }

    @media (min-width: 1280px) {
        margin: 64px 0 155px 0;
        padding-left: 32px;

        > h2 {
            margin-right: -3ch;
        }

        > a {
            margin-top: 32px;
        }
    }

    @media (min-width: 1800px) {
        margin-left: auto;
    }
`;

const ServicesWrapper = styled.div`
    @media (min-width: 1280px) {
        position: relative;
        width: calc(100vw - 550px);
        display: flex;
        justify-content: center;
    }

    @media (min-width: 1600px) {
        width: calc(100vw - 700px);
    }

    @media (min-width: 1800px) {
        width: calc(1715px - 822px);
    }

    @media (min-width: 1900px) {
        width: calc(1715px - 944px);
    }
`;

const Services = styled.div`
    display: flex;
    margin: 16px 0 0 16px;
    padding-bottom: 42px;
    overflow-x: scroll;
    gap: 16px;

    > div {
        display: flex;
        gap: 16px;
    }

    @media (min-width: 1280px) {
        overflow-x: unset;
        margin: 12% auto 0;
        padding-bottom: 0;
        position: absolute;
        gap: 32px;

        > div {
            flex-direction: column;
            gap: 32px;

            :first-of-type {
                margin-top: 25%;
            }
        }
    }

    @media (min-width: 1440px) {
        > div:first-of-type {
            margin-top: 17%;
        }
    }

    @media (min-width: 1600px) {
        margin-top: 15%;

        > div:first-of-type {
            margin-top: 25%;
        }
    }

    @media (min-width: 1800px) {
        margin-top: 20%;

        > div:first-of-type {
            margin-top: 33%;
        }
    }

    @media (min-width: 1900px) {
        margin-top: 37%;

        > div:first-of-type {
            margin-top: 37%;
        }
    }
`;

const Card = styled.div`
    width: 314px;
    padding: 64px 32px;
    border-radius: 64px;
    background: ${({ theme }) => theme.bg};
    transition: all 0.3s ease-in-out;
    transition-property: background, box-shadow;
    z-index: 2;
    display: flex;
    flex-direction: column;

    ::before {
        content: url(${tooth});
        width: 64px;
        height: 64px;
    }

    :nth-of-type(2) {
        background: ${({ theme }) => theme.kindaWhite};
    }

    :hover {
        background: ${({ theme }) => theme.white};
        box-shadow: 0px -16px 32px rgba(255, 255, 255, 0.2), 0px 32px 32px rgba(33, 163, 185, 0.2);
    }

    h3 {
        margin: 32px 0 0;
        font-size: 1.5rem;
        text-transform: capitalize;
    }

    p {
        margin: 32px 0;
        font-size: 1.25rem;
    }

    a {
        margin-top: auto;
        align-self: flex-start;
    }

    @media (min-width: 1280px) {
        order: ${({ order }) => order};
        width: 280px;
        padding: 56px 24px;

        p {
            font-size: 1rem;
        }
    }

    @media (min-width: 1440px) {
        width: 314px;
        padding: 64px 32px;

        p {
            font-size: 1.125rem;
        }
    }

    @media (min-width: 1600px) {
        p {
            font-size: 1.25rem;
        }
    }
`;

const services = [
    {
        title: 'InVisalign',
        description: "Unleash Your Smile With The World's Most Advanced Aligner System!",
        link: '/invisalign/',
    },
    {
        title: 'kids braces',
        description:
            'As your child begins to loose their baby teeth and permanent teeth come in, you may begin to notice ...',
        link: '/kids-braces/',
    },
    {
        title: 'types of braces',
        description: "Unleash Your Smile With The World's Most Advanced Aligner System!",
        link: '/types-of-braces/',
    },
    {
        title: 'adult braces',
        description:
            'Who says braces are just for kids? In fact, adult orthodontic treatment is up 40% from just two decades ago...',
        link: '/adult-braces/',
    },
];

export default function HomeServices() {
    return (
        <Section>
            <ImgContainer>
                <StaticImage
                    src="../../images/home-braces.png"
                    alt="land orthodontics braces"
                    breakpoints={[320, 550, 768, 944]}
                    css={css`
                        max-width: min(93.33vw, 500px);
                        filter: drop-shadow(32px 32px 64px rgba(33, 163, 185, 0.3));
                        border-radius: 0px 64px 64px 0px;

                        @media (min-width: 768px) {
                            max-width: min(65vw, 600px);
                        }

                        @media (min-width: 1280px) {
                            display: none;
                        }
                    `}
                />
                <Box>
                    <h2>Braces and InVisalign for all ages.</h2>
                    <SecondaryBtn as="a" href="#contact-us">
                        Book an Appointment
                    </SecondaryBtn>
                </Box>

                <StaticImage
                    src="../../images/home-braces.png"
                    alt="land orthodontics braces"
                    css={css`
                        display: none;

                        @media (min-width: 1280px) {
                            display: block;
                        }
                    `}
                />
            </ImgContainer>
            <ServicesWrapper>
                <Services>
                    <div>
                        {services.slice(0, 2).map((service, i) => (
                            <Card key={i}>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                                <SecondaryBtn as={Link} to={service.link}>
                                    Learn About {service.title}
                                </SecondaryBtn>
                            </Card>
                        ))}
                    </div>

                    <div>
                        {services.slice(2).map((service, i) => (
                            <Card key={i} order={i === 1 ? -1 : 0}>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                                <SecondaryBtn as={Link} to={service.link}>
                                    Learn About {service.title}
                                </SecondaryBtn>
                            </Card>
                        ))}
                    </div>
                </Services>
            </ServicesWrapper>
        </Section>
    );
}
