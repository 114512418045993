import styled from '@emotion/styled';
import React from 'react';
import wave from '../../images/about/wave.svg';
import AboutDr from '../AboutDr';
import { padding } from '../layout/GlobalStyles';
import { py32 } from './../layout/GlobalStyles';

const Section = styled.section`
    ${padding};
    ${py32};
    background-color: ${({ theme }) => theme.bg};
    padding: 16px;
    position: relative;
    z-index: 1;

    @media (min-width: 1280px) {
        padding: 378px 16px 128px;
    }

    @media (min-width: 1600px) {
        padding-bottom: 378px;

        ::after {
            content: url(${wave});
            position: absolute;
            right: 0;
            bottom: -8px;
            z-index: -1;
        }
    }
`;
export default function HomeDr({ homepage }) {
    return (
        <Section>
            <AboutDr
                description="Dr. Herbert K. Land has been bringing beautiful smiles for over 36 years to patients in North Carolina and now in St. Croix. He resides Full-Time on the island and cares for his patients routinely, as well as when needed for emergencies."
                homepage={homepage}
            />
        </Section>
    );
}
