import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { h1, h3 } from '../Headings';
import InvisalignBox from '../InvisalignBox';

const Section = styled.section`
    padding: 32px 21px;
    position: relative;

    @media (min-width: 1024px) {
        padding: 64px 24px;
    }

    @media (min-width: 1280px) {
        padding: 128px 0 339px;
    }
`;

const H5 = styled.h5`
    ${h1};
    margin: 0 0 16px;
    color: ${({ theme }) => theme.lightTeal};

    @media (min-width: 768px) {
        margin: 0;
    }
`;

const Benefits = styled.ul`
    color: ${({ theme }) => theme.darkTeal};
    margin: 0;

    > li {
        ${h3};
        margin-bottom: 32px;

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        max-width: 500px;
    }
`;

const Box = styled.div`
    max-width: 775px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto 32px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 64px;
    }

    @media (min-width: 1280px) {
        margin-bottom: 96px;
    }

    @media (min-width: 1600px) {
        max-width: 896px;
        margin-bottom: 128px;
    }
`;

export default function HomeInvisalign() {
    return (
        <Section>
            <StaticImage
                src="../../images/invisalign/section-bg.png"
                alt="background"
                layout="fullWidth"
                breakpoints={[375, 550, 750, 1080, 1366, 1920]}
                css={css`
                    position: absolute;
                    inset: 0;
                    z-index: -100;
                `}
            />
            <Box>
                <H5>
                    Get
                    <br />
                    InVisalign
                </H5>
                <Benefits>
                    <li>affordable monthly payments with no money down.</li>
                    <li> using your insurance coverage</li>
                    <li> paying with pre-tax dollars from your HSA</li>
                </Benefits>
            </Box>
            <InvisalignBox />
        </Section>
    );
}
