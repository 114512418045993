import React from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import HomeDr from '../components/Sections/HomeDr';
import HomeHero from '../components/Sections/HomeHero';
import HomeInvisalign from '../components/Sections/HomeInvisalign';
import HomeServices from '../components/Sections/HomeServices';
import HomeSteps from '../components/Sections/HomeSteps';

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Home" />
            <HomeHero />
            <HomeServices />
            <HomeDr homepage />
            <HomeSteps />
            <HomeInvisalign />
        </Layout>
    );
};

export default IndexPage;
